<template>
  <div :style="{ padding: '200px 100px' }">
    <div :style="{ padding: '50px 0' }">
      <div><b>Input with prop, message="????" and no rules</b></div>
      <div><b>Message dissapears after any change to the text box</b></div>
      <ValidInput vid="Input1" label="Text Box" message="????" />
    </div>

    <div :style="{ padding: '50px 0' }">
      <div><b>Input with prop, message="????" and rule email="true"</b></div>
      <div>
        message prop is replaced with vee-validate email message after change, if the change results
        in an invalid email
      </div>

      <ValidInput
        vid="Email1"
        label="Email"
        :rules="{
          email: true
        }"
        message="????"
      />
    </div>

    <div :style="{ padding: '50px 0' }">
      <div><b>Input with prop, message="????" and rule email="true"</b></div>
      <div>At any time a new message can be sent to the component</div>
      <ValidInput
        vid="Email1"
        label="Email"
        :rules="{
          email: true
        }"
        :message.sync="message"
        value="some value"
      />

      <input ref="input" :style="{ border: '1px solid #888' }" />
      <b-button @click="updateMessage">update message</b-button>
    </div>
  </div>
</template>

<script>
import ValidInput from './../index'

export default {
  components: {
    ValidInput
  },
  data() {
    return {
      workEmail: 'asdfasdf',
      message: `????`
    }
  },
  methods: {
    updateMessage() {
      console.log(this.$refs)

      this.message = this.$refs.input.value
    }
  }
}
</script>
